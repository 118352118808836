<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="34px" height="34px" viewBox="0 0 34 34" enable-background="new 0 0 34 34" xml:space="preserve">
    <title>570A8283-B47F-4E90-8A78-1830A240DB50</title>
    <desc>Created with sketchtool.</desc>
    <path fill="#FFFFFF" d="M6.563,9.633H3.587c-1.792,0-3.25-1.458-3.25-3.25V3.25C0.337,1.458,1.795,0,3.587,0h2.976
        c1.792,0,3.25,1.458,3.25,3.25v3.133C9.813,8.175,8.355,9.633,6.563,9.633z M3.587,2.5c-0.414,0-0.75,0.336-0.75,0.75v3.133
        c0,0.414,0.336,0.75,0.75,0.75h2.976c0.414,0,0.75-0.336,0.75-0.75V3.25c0-0.414-0.336-0.75-0.75-0.75H3.587z"/>
    <path fill="#FFFFFF" d="M6.563,21.533H3.587c-1.792,0-3.25-1.458-3.25-3.25V15.15c0-1.792,1.458-3.25,3.25-3.25h2.976
        c1.792,0,3.25,1.458,3.25,3.25v3.133C9.813,20.075,8.355,21.533,6.563,21.533z M3.587,14.4c-0.414,0-0.75,0.336-0.75,0.75v3.133
        c0,0.413,0.336,0.75,0.75,0.75h2.976c0.414,0,0.75-0.337,0.75-0.75V15.15c0-0.414-0.336-0.75-0.75-0.75H3.587z"/>
    <path fill="#FFFFFF" d="M6.563,34H3.587c-1.792,0-3.25-1.458-3.25-3.25v-3.133c0-1.792,1.458-3.25,3.25-3.25h2.976
        c1.792,0,3.25,1.458,3.25,3.25v3.133C9.813,32.542,8.355,34,6.563,34z M3.587,26.867c-0.414,0-0.75,0.337-0.75,0.75v3.133
        c0,0.413,0.336,0.75,0.75,0.75h2.976c0.414,0,0.75-0.337,0.75-0.75v-3.133c0-0.413-0.336-0.75-0.75-0.75H3.587z"/>
    <path fill="#FFFFFF" d="M18.825,9.633H15.85c-1.792,0-3.25-1.458-3.25-3.25V3.25C12.6,1.458,14.058,0,15.85,0h2.976
        c1.792,0,3.25,1.458,3.25,3.25v3.133C22.075,8.175,20.617,9.633,18.825,9.633z M15.85,2.5c-0.414,0-0.75,0.336-0.75,0.75v3.133
        c0,0.414,0.336,0.75,0.75,0.75h2.976c0.413,0,0.75-0.336,0.75-0.75V3.25c0-0.414-0.337-0.75-0.75-0.75H15.85z"/>
    <path fill="#FFFFFF" d="M18.825,21.533H15.85c-1.792,0-3.25-1.458-3.25-3.25V15.15c0-1.792,1.458-3.25,3.25-3.25h2.976
        c1.792,0,3.25,1.458,3.25,3.25v3.133C22.075,20.075,20.617,21.533,18.825,21.533z M15.85,14.4c-0.414,0-0.75,0.336-0.75,0.75v3.133
        c0,0.413,0.336,0.75,0.75,0.75h2.976c0.413,0,0.75-0.337,0.75-0.75V15.15c0-0.414-0.337-0.75-0.75-0.75H15.85z"/>
    <path fill="#FFFFFF" d="M18.825,34H15.85c-1.792,0-3.25-1.458-3.25-3.25v-3.133c0-1.792,1.458-3.25,3.25-3.25h2.976
        c1.792,0,3.25,1.458,3.25,3.25v3.133C22.075,32.542,20.617,34,18.825,34z M15.85,26.867c-0.414,0-0.75,0.337-0.75,0.75v3.133
        c0,0.413,0.336,0.75,0.75,0.75h2.976c0.413,0,0.75-0.337,0.75-0.75v-3.133c0-0.413-0.337-0.75-0.75-0.75H15.85z"/>
    <path fill="#FFFFFF" d="M30.529,9.633h-2.975c-1.792,0-3.25-1.458-3.25-3.25V3.25c0-1.792,1.458-3.25,3.25-3.25h2.975
        c1.792,0,3.25,1.458,3.25,3.25v3.133C33.779,8.175,32.321,9.633,30.529,9.633z M27.555,2.5c-0.413,0-0.75,0.336-0.75,0.75v3.133
        c0,0.414,0.337,0.75,0.75,0.75h2.975c0.413,0,0.75-0.336,0.75-0.75V3.25c0-0.414-0.337-0.75-0.75-0.75H27.555z"/>
    <path fill="#FFFFFF" d="M30.529,21.533h-2.975c-1.792,0-3.25-1.458-3.25-3.25V15.15c0-1.792,1.458-3.25,3.25-3.25h2.975
        c1.792,0,3.25,1.458,3.25,3.25v3.133C33.779,20.075,32.321,21.533,30.529,21.533z M27.555,14.4c-0.413,0-0.75,0.336-0.75,0.75v3.133
        c0,0.413,0.337,0.75,0.75,0.75h2.975c0.413,0,0.75-0.337,0.75-0.75V15.15c0-0.414-0.337-0.75-0.75-0.75H27.555z"/>
    <path fill="#FFFFFF" d="M30.529,34h-2.975c-1.792,0-3.25-1.458-3.25-3.25v-3.133c0-1.792,1.458-3.25,3.25-3.25h2.975
        c1.792,0,3.25,1.458,3.25,3.25v3.133C33.779,32.542,32.321,34,30.529,34z M27.555,26.867c-0.413,0-0.75,0.337-0.75,0.75v3.133
        c0,0.413,0.337,0.75,0.75,0.75h2.975c0.413,0,0.75-0.337,0.75-0.75v-3.133c0-0.413-0.337-0.75-0.75-0.75H27.555z"/>
    </svg>
</template>

<script>
export default {

}
</script>