<template>
  <div
    :class="[
      ...appClasses(),
      'operator-feed',
      'bookeasy-feed',
      'operators-container',
      'operators-container--' + operatorListingType.slug
    ]"
  >
    <div class="filter-tabs" v-if="!this.bookEasy.campaignId">
      <a
        :href="`${base_url}/${type}/`"
        v-for="(type, index) in this.listingTypes"
        :key="index"
        :class="['filter-tab', { active: operatorListingType.slug == type }]"
        >{{ type }}
      </a>
    </div>
    <search-filter
      heading
      :displayKeywords="false"
      :categories="[]"
      :displaySearchBtn="false"
      :displayResetBtn="true"
      v-on:update:category="updateCategory($event)"
      @click:reset="resetFilter($event)"
      @click:search="searchFilterSubmit()"
      :displayDateRange="false"
    >
      <template slot="precategories">
        <div class="input-container date-range-picker">
          <label for="filter-date-picker">Date</label>
          <input
            id="filter-date-picker"
            class="flatpickr flatpickr-input active"
            type="text"
            placeholder="Select Date..."
          />
        </div>

        <div
          v-if="bookEasy.listingType == 'accom'"
          class="input-container input-container--small"
        >
          <label for="filter-category">Nights</label>
          <select
            id="filter-category"
            @change="updateFilter('period', $event.target.value)"
            v-model="filterDataSelected.period"
          >
            <option v-for="(item, index) in 31" :value="index" :key="index">{{
              index
            }}</option>
          </select>
        </div>

        <div class="input-container input-container--small">
          <label for="filter-category">Adults</label>
          <select
            id="filter-category"
            @change="updateFilter('adults', $event.target.value)"
            v-model="filterDataSelected.adults"
          >
            <option v-for="(item, index) in 46" :value="index" :key="index">{{
              index
            }}</option>
          </select>
        </div>
        <div class="input-container input-container--small">
          <label for="filter-category">Children</label>
          <select
            id="filter-category"
            @change="updateFilter('children', $event.target.value)"
            v-model="filterDataSelected.children"
          >
            <option v-for="(item, index) in 46" :value="index" :key="index">{{
              index
            }}</option>
          </select>
        </div>
        <div class="input-container input-container--small">
          <label for="filter-category">Infants</label>
          <select
            id="filter-category"
            @change="updateFilter('infants', $event.target.value)"
            v-model="filterDataSelected.infants"
          >
            <option v-for="(item, index) in 46" :value="index" :key="index">{{
              index
            }}</option>
          </select>
        </div>
      </template>

      <template slot="footer">
        <div class="filter__advanced" v-if="displayAdvanced">
          <div>
            <div class="input-container">
              <label for="filter-keywords">Keywords</label>
              <input
                id="filter-keywords"
                type="text"
                placeholder="keyword"
                @keyup="updateKeywords($event.target.value)"
                v-model="keywords"
              />
            </div>

            <!-- <div v-if="categories.length > 0" class="input-container">
              <label for="filter-category">Category</label>
              <select id="filter-category" @change="updateCategory($event.target.value)" v-model="selectedCategory">
                  <option value="">Category</option>
                  <option v-for="(category, index) in categories" :value="category.value" :key="index" v-html="category.label"></option>
              </select>
            </div> -->

            <!-- <div v-if="facilities.length > 0" class="input-container">
              <label for="filter-facility">Facility</label>
              <select
                id="filter-facility"
                @change="updateFacility(selectedFacility)"
                v-model="selectedFacility"
              >
                <option value>Facility</option>
                <option
                  v-for="(item, index) in facilities"
                  :value="item.label"
                  :key="index"
                  :selected="item.label == selectedFacility"
                  >{{ item.label }}</option
                >
              </select>
            </div> -->

            <div v-if="locations.length > 0" class="input-container">
              <label for="filter-category">Location</label>
              <select
                id="filter-category"
                @change="updateLocation($event.target.value)"
                v-model="selectedLocation"
              >
                <option value>Location</option>
                <option
                  v-for="(item, index) in locations"
                  :value="item.value"
                  :key="index"
                  :selected="item.value == selectedLocation"
                  >{{ item.label }}</option
                >
              </select>
            </div>

            <div class="input-container">
              <label for="filter-sortby">Sort By</label>
              <select id="filter-sortby" v-model="selectedSortOrder">
                <option value="confirmation">Instant Confirmation</option>
                <option value="name">Name</option>
                <option value="deals">Deals</option>
                <option value="price">Price</option>
              </select>
            </div>
            <div class="filter__advanced-sub-options">
              <label class="input-container--checkbox">
                Reverse Order
                <input type="checkbox" v-model="isSortOrderReversed" />
                <span class="checkmark"></span>
              </label>

              <label class="input-container--checkbox">
                Instant Confirmations only
                <input type="checkbox" v-model="displayInstantlyBookableOnly" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <h4 v-if="mainCategories.length > 0" class="filter__heading">
            Categories
          </h4>
          <!-- <div class="filter__subcategories input-container">
                  <button v-for="(category, index) in this.subCategories" @click="updateSubCategory(category)" type="button" :key="index" :class="[...subCategoriesClass(category.term_id)]" v-html="category.label"></button>
          </div>-->
          <div
            v-if="mainCategories.length > 0"
            class="filter__subcategories input-container"
            :class="{
              'has-selected-subcategories': selectedSubCategories.length > 0
            }"
          >
            <button
              v-for="(category, index) in mainCategories"
              @click="updateCategoryArray(category.value)"
              type="button"
              :key="index"
              :class="[
                ...subCategoriesClass(category.value, 'selectedCategory')
              ]"
              v-html="category.label"
            ></button>
          </div>
        </div>
        <button
          :class="['filter__advanced-search-btn', { active: displayAdvanced }]"
          type="button"
          @click="displayAdvanced = !displayAdvanced"
        >
          {{ displayAdvanced ? "Close Advanced" : "Open Advanced" }}
        </button>

        <div class="operator-feed__views">
          <button
            :class="[
              'operator-feed__view-button',
              'operator-feed__view-button--list',
              { active: !isMapView }
            ]"
            @click="isMapView = false"
          >
            <icon-list-view></icon-list-view>Grid view
          </button>
          <button
            :class="[
              'operator-feed__view-button',
              'operator-feed__view-button--map',
              { active: isMapView }
            ]"
            @click="isMapView = true"
          >
            <icon-map-view></icon-map-view>Map View
          </button>
        </div>
      </template>
    </search-filter>

    <div
      v-if="filteredData.length < 1 && !isLoading"
      class="text-center content-container"
    >
      <h4>There are no results for this search.</h4>
    </div>

    <keep-alive>
      <div v-if="!isMapView">
      <div
        class="pagination"
        v-if="alphaPagination.length > 1 || selectedPaginationItem"
      >
        <span class="pagination__prepend-text">Starting with:</span>
        <button 
          type="button" 
          @click="updatePaginationItem({label: false})"
          :class="[
            'pagination-item',
            { active: false == selectedPaginationItem }
          ]"
        >
          All
        </button>
        <button
          v-for="(item, index) in alphaPagination"
          @click="updatePaginationItem(item)"
          type="button"
          :key="index"
          :class="[
            'pagination-item',
            { active: item.label == selectedPaginationItem }
          ]"
        >
          {{ item.label }}
        </button>
      </div>
        <div class="article-list__items article-list-js">
          <article-list-item
            v-for="(item, index) in filteredData"
            :item="item"
            :key="item.id"
            :class="[
              'article-list__item',
              { 'last-item': index === operators.length - 1 },
              { 'proud-member': item.operator_data.is_proud_member == true },
              { 'fw-member': item.operator_data.is_fw_member == true },
              {
                'is-single-member':
                  (!item.operator_data.is_fw_member &&
                    item.operator_data.is_proud_member) ||
                  (item.operator_data.is_fw_member &&
                    !item.operator_data.is_proud_member)
              }
            ]"
          >
            <template slot="details">
              <p v-if="item.operator_data.address" class="address">
                {{ item.operator_data.address }}
              </p>
              <!-- <p v-if="item.operator_data.phone" class="phone">{{item.operator_data.phone}}</p> -->
            </template>
            <template slot="button">
              <p
                v-if="item.operator_data.has_hot_deals"
                class="hot-deals-available"
              >
                Hot Deals Available
              </p>
              <a
                v-if="getFromPrice(item.operator_data)"
                :href="`${item.url}#book-now`"
                class="btn book"
              >
                {{
                  item.operator_data.is_gold_medal ? `Book Now` : `Reserve Now`
                }}
                <span class="book__price"
                  >${{ getFromPrice(item.operator_data) }}</span
                >
              </a>
              <a v-else :href="item.url" class="btn book">View Details</a>
              <!-- <a
                v-if="item.operator_data.email && !getFromPrice(item.operator_data)"
                :href="'mailto:'+item.operator_data.email"
                class="btn email"
                :data-print-text="item.operator_data.email"
              >Email</a>
              <a
                v-if="item.operator_data.website && !getFromPrice(item.operator_data)"
                :href="item.operator_data.website"
                class="btn web"
                target="_blank"
                :data-print-text="item.operator_data.website"
              >Web</a>
              <a
                v-if="item.operator_data.phone && !getFromPrice(item.operator_data)"
                :href="'tel:'+item.operator_data.phone"
                class="btn call"
                :data-print-text="item.operator_data.phone"
              >Call</a>-->
            </template>
          </article-list-item>
        </div>
        <div class="load-more-wrapper">
          <button
            v-if="pagesTotal > pageNumber && !isLoading"
            type="button"
            class="load-more button button--secondary"
            @click="displayMorePosts()"
          >
            Load More
          </button>
          <div
            class="loader"
            v-if="isLoading && (!this.isNewSearch || this.pagesTotal === 0)"
          ></div>
        </div>
      </div>
      <operator-map
        v-if="isMapView && !isLoading && filteredDataAllWithMarkers.length > 0"
        :operatorData="this.filteredDataAllByPagination"
      ></operator-map>
    </keep-alive>
    <!-- <footer v-if="!isLoading && filteredData.length > 0 && !isMapView" class="article-links">
      <button class="print-button" onclick="window.print(); return false;">Print List</button>
    </footer>-->
  </div>
</template>

<script>
import axios from "axios";
import OperatorMap from "./OperatorMap.vue";
import ArticleListItem from "./ArticleListItem.vue";
import SearchFilter from "./SearchFilter.vue";
import iconListView from "./svg/svgList.vue";
import iconMapView from "./svg/svgMap.vue";
import mixins from "./utilities.js";
import pagination from "./alphaPagination.js";

/* eslint-disable no-unused-vars */
import flatpickr from "flatpickr";
import flatpickrStyles from "flatpickr/dist/flatpickr.min.css";
/* eslint-enable no-unused-vars */

export default {
  name: "Operators",
  props: {},
  mixins: [mixins, pagination],
  components: {
    OperatorMap,
    ArticleListItem,
    SearchFilter,
    iconListView,
    iconMapView
  },
  data: function() {
    return {
      displayAdvanced: false,
      locations: [],
      allCategories: [],
      allCategoriesString: "",
      categories: [],
      facilities: [],
      datePicker: "",
      displaySearchBtn: false,
      selectedCategory: [],
      selectedFacility: "",
      selectedLocation: "",
      selectedSubCategories: [],
      selectedPaginationItem: false,
      selectedSortOrder: "confirmation",
      isSortOrderReversed: false,
      displayInstantlyBookableOnly: false,
      operators: [],
      operatorUrls: [],
      keywords: "",
      itemsPerPage: 9,
      pageNumber: 1,
      postType: "",
      pagesTotal: 0,
      showLoadMore: false,
      isLoading: false,
      isNewSearch: true, // when "loading more" this is set to false
      restParamsString: "",
      queryString: "",
      isMapView: false,
      operatorListingType: null,
      operator_category: "",
      mapOperators: [],
      filterData: { keywords: "", category: "" }, //store data entered in filter before user presses submit, for when we dont want our list to auto filter
      filterDataDefaults: {
        date: "",
        period: 1, // only used for accom
        adults: 2,
        children: 0,
        infants: 0,
        type: ""
      },
      filterDataSelected: {
        date: flatpickr.formatDate(new Date(), "Y-m-d"),
        period: 1, // only used for accom
        adults: 2,
        children: 0,
        infants: 0
      },
      bookEasy: {
        apiKey: "",
        vcId: "",
        listingType: "accom", // used in bookeasy api queries
        enforceBookingConditions: false,
        enforceEntirePeriod: false,
        campaignId : 0,
      },
      paginationItems: "filteredDataAll", // variable name of items you are paginating
      showAll: true, // don't enforce filter options for accomm
      listingTypes: ["accommodation", "tours"],
      base_url: ""
    };
  },
  watch: {
    filteredDataAllByPagination: function() {
      this.pagesTotal = Math.ceil(
        this.filteredDataAllByPagination.length / this.itemsPerPage
      );
      console.log("filterd data all cahneg total");
    }
  },
  mounted: function() {
    console.warn("feed MOUNTED");
    let _this = this;

    this.getLocations();
    this.getCategories();

    let datePicker = document.getElementById("filter-date-picker");

    this.datePicker = flatpickr(datePicker, {
      //mode: "range",
      defaultDate: "today",
      altInput: true,
      altFormat: "d/m/Y", //format for display only
      //onChange: function(selectedDates, dateStr, instance) {
      onChange: function(selectedDates) {
        if (selectedDates.length > 0) {
          if (
            flatpickr.formatDate(selectedDates[0], "Y-m-d") !=
            _this.filterDataSelected.date
          ) {
            // _this.filterDataSelected.date= flatpickr.formatDate(selectedDates[0], "Y-m-d");
            _this.getPosts();
            //_this.updateUrl();
            _this.updateFilter(
              "date",
              flatpickr.formatDate(selectedDates[0], "Y-m-d")
            );
          }
        }
      },
      "disable": [
        function(date) {
            // return true to disable
            let today = new Date()
            today.setHours(0,0,0,0);
            return date < today
        }
      ],
    });

    this.filterDataDefaults.date = flatpickr.formatDate(new Date(), "Y-m-d"); // todays date as default
    this.datePicker.setDate(this.filterDataDefaults.date);

    if (this.filterDataSelected.date >= this.filterDataDefaults.date)
      this.datePicker.setDate(this.filterDataSelected.date);
    else this.filterDataSelected.date = this.filterDataDefaults.date;

    this.filterDataDefaults.type = this.operatorListingType.slug;
  },
  created: function() {
    if (this.pageInfo.be) {
      if (this.pageInfo.be.vc_id) this.bookEasy.vcId = this.pageInfo.be.vc_id;
      if (this.pageInfo.be.api_key) this.bookEasy.apiKey = this.pageInfo.be.api_key;
    }

    this.base_url = `${this.pageInfo.site_url}/book`;

    this.getPostsRequest = this.makeAJAXRequest();
    this.debounceKeywords = this.delay();

    this.operatorListingType = this.pageInfo.operator_category.slug;

    this.operator_category = this.pageInfo.operator_category;
    this.operatorListingType = this.pageInfo.operator_listing_type;
    this.filterDataSelected.type = this.operatorListingType.slug;

    if (this.operatorListingType.slug !== "accommodation")
      this.bookEasy.listingType = this.operatorListingType.slug;

    if (this.pageInfo.be_campaign_id) {
      this.bookEasy.campaignId = this.pageInfo.be_campaign_id;
      this.bookEasy.enforceBookingConditions = true;
      this.bookEasy.enforceEntirePeriod = true;
    }

    this.postType = "members";

    if (
      this.pageInfo.operator_category.term_id !=
      this.pageInfo.operator_listing_type.term_id
    ) {
      //onload
      this.selectedCategory.push(this.pageInfo.operator_category.term_id);
      this.displayAdvanced = true;
    }

    /*
      const urlDate = decodeURI(this.getQueryVariable('date'));
      const urlAdults = parseInt(decodeURI(this.getQueryVariable('adults')));
      const urlChildren = parseInt(decodeURI(this.getQueryVariable('children')));
      const urlInfants = parseInt(decodeURI(this.getQueryVariable('infants')));
      const urlPeriod = parseInt(decodeURI(this.getQueryVariable('period')));

      if (isNaN(new Date(urlDate)) == false)
        this.filterDataSelected.date = urlDate;

      if (urlAdults && urlAdults < 46)
        this.filterDataSelected.adults = urlAdults

      if (urlChildren && urlChildren < 46)
        this.filterDataSelected.children = urlChildren

      if (urlInfants && urlInfants < 46)
        this.filterDataSelected.infants = urlInfants

      if (urlPeriod && urlPeriod < 31)
        this.filterDataSelected.period = urlPeriod
      */

    // if (this.operatorListingType.name == "Accommodation") {
      this.selectedLocation = ''
      this.getBEFacilities();
    // } else {
    //   this.getFacilities();
    //   this.selectedLocation = ''
    // }

    this.updateLocalStorage();

    window.onpopstate = function(event) {
      console.warn(
        "location: " +
          document.location +
          ", state: " +
          JSON.stringify(event.state)
      );
    };

    let _this = this;

    if (this.operatorListingType.slug == "accommodation") {
      // get our operator urls first
      this.getPostsRequest(
        `${this.pageInfo.site_url}/wp-json/wisdom-operators/v1/operators?minimaldata=1&operator_type=${_this.operatorListingType.slug}`,
        {}
      ).then(function(response) {
        _this.operatorUrls = response.data;

        // then get the posts from bookeasy
        _this.getPosts();
      });
    } else {
      //  eg tours. Which are Wordpress AND Bookeasy
      _this.getPosts();
    }
  },
  methods: {
    getLocations() {
      let _this = this;

      // if (this.operatorListingType.slug == "accommodation") {
        axios
          .get(
            `https://webapi.bookeasy.com.au/api/getVcLocations?q=${_this.bookEasy.vcId}&key=${_this.bookEasy.apiKey}`,
            {}
          )
          .then(function(response) {
            response.data.Locations.forEach(item => {
              _this.locations.push({
                value: item["LocationId"],
                label: item["Description"]
                //slug: item["slug"]
              });
            });
          })
          .catch(function(error) {
            console.error(error);
          });
      // } else {
      //   axios
      //     .get(
      //       _this.base_url +
      //         `operator_location?post_type=${_this.postType}&per_page=100`,
      //       {}
      //     )
      //     .then(function(response) {
      //       response.data.forEach(item => {
      //         _this.locations.push({
      //           value: item["id"],
      //           label: item["name"],
      //           slug: item["slug"]
      //         });
      //       });
      //     })
      //     .catch(function(error) {
      //       console.error(error);
      //     });
      // }
    },
    getCategories() {
      let _this = this;

      //let catQuery = `${this.pageInfo.site_url}/wp-json/wisdom-operators/v1/subcategories/${this.pageInfo.operator_listing_type.term_id}?hide_empty=1`
      let catQuery = `${this.pageInfo.site_url}/wp-json/wp/v2/member-category?per_page=100&hide_empty=1`;

      axios
        .get(catQuery, {})
        .then(function(response) {
          response.data.forEach(item => {
            _this.categories.push({
              value: item["id"] ? item["id"] : item["term_id"],
              //value: _this.decodeEntities(item["name"]),
              label: item["name"],
              slug: item["slug"],
              term_id: item["id"] ? item["id"] : item["term_id"],
              parent: item["parent"]
            });
          });
        })
        .catch(function(error) {
          console.error(error);
        });

      /*
        axios
        .get(`https://webapi.bookeasy.com.au/api/get${_this.bookEasy.listingType}Attributes?q=${_this.bookEasy.vcId}&key=${_this.bookEasy.apiKey}`, {})
        .then(function(response) {
          response.data.Types.forEach(item => {
            _this.categories.push({
              value: item,
              label: item
              }
            );

          });
        })
        .catch(function(error) {
          console.error(error);
        });
        */

      /* below if from bookeasy */
    },
    getBEFacilities() {
      let _this = this;

      let facilityQuery = `https://webapi.bookeasy.com.au/api/getAccomAttributes?q=${this.bookEasy.vcId}`;

      _this.facilities = []; //reset

      axios
        .get(facilityQuery, {})
        .then(function(response) {
          response.data.Facilities.forEach((item, index) => {
            _this.facilities.push({
              value: index,
              label: item,
              slug: "",
              term_id: index
            });
          });
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    getFacilities() {
      let _this = this;

      _this.facilities = []; //reset

      let catQuery = `${this.pageInfo.site_url}/wp-json/wp/v2/operator_facilities?per_page=100`;

      axios
        .get(catQuery, {})
        .then(function(response) {
          response.data.forEach(item => {
            _this.facilities.push({
              value: item["id"] ? item["id"] : item["term_id"],
              label: item["name"],
              slug: item["slug"],
              term_id: item["id"] ? item["id"] : item["term_id"]
            });
          });
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    updateFacility(facility) {
      this.selectedFacility = facility;
      this.selectedPaginationItem = false; //reset

      if (this.operatorListingType.slug == "tours") {
        this.getPosts();
      }
    },
    updateCategory($category) {
      // for single select category
      console.warn($category);
      if (!this.displaySearchBtn) {
        //this.category = $category; // automatically update the list
        this.selectedCategory = $category;
        this.selectedSubCategories = []; //reset
        this.selectedPaginationItem = false; //reset
        //this.displayAdvanced = false;
        this.getPosts();
      } else {
        this.filterData["category"] = $category; // save it for when user presses submit
      }
      this.updateUrl();
    },
    updateCategoryArray(category) {
      // for multi select category
      console.warn(category);
      console.warn("lili");
      if (!this.displaySearchBtn) {
        const catIndex = this.selectedCategory.indexOf(category);
        if (catIndex == -1) this.selectedCategory.push(category);
        else this.selectedCategory.splice(catIndex, 1);

        //this.selectedSubCategories = []; //reset // no sub cats
        this.selectedPaginationItem = false; //reset
        //this.displayAdvanced = false;
        //this.getPosts();

        if (this.operatorListingType.slug == "tours") {
          this.getPosts();
        }

        this.updateUrl();
      } else {
        //this.filterData["category"] = category; // save it for when user presses submit
      }
    },
    updateLocation($location) {
      if (!this.displaySearchBtn) {
        // automatically update the list
        this.selectedLocation = $location;
        this.selectedPaginationItem = false; //reset
        //this.displayAdvanced = false;

        if (this.operatorListingType.slug == "tours") {
          this.getPosts();
        }
      } else {
        this.filterData["location"] = $location; // save it for when user presses submit
      }
      //this.updateUrl();
    },
    updateSubCategory(cat) {
      const catIndex = this.selectedSubCategories.indexOf(cat.term_id);
      if (catIndex == -1) this.selectedSubCategories.push(cat.term_id);
      else this.selectedSubCategories.splice(catIndex, 1);

      this.selectedPaginationItem = false; //reset

      this.getPosts();
      this.updateUrl();
      //window.history.replaceState({"html":"lili","pageTitle":"response.pageTitle", "selectedCats": this.selectedSubCategories},"", "./lili"+cat.slug);
    },
    updatePaginationItem(item) {
      if (this.selectedPaginationItem == item.label) {
        this.selectedPaginationItem = false; //toggle off if clicked on again
        this.pagesTotal = Math.ceil(this.operators.length / this.itemsPerPage);
      } else {
        this.selectedPaginationItem = item.label;
        this.pagesTotal = Math.ceil(item.count / this.itemsPerPage);
      }

      this.pageNumber = 1;

      const operatorsContainer = document.querySelector(
        ".article-list__items, .operator-feed__map-container"
      );
      setTimeout(function() {
        window.scrollTo({
          top: operatorsContainer.offsetTop - 120,
          behavior: "smooth"
        });
        console.log("SCROL NOW");
      }, 100);
    },
    resetFilter() {
      this.keywords = this.selectedLocation = "";
      this.selectedCategory = [];

      if (this.operatorListingType.slug == "tours")
        this.selectedCategory.push(this.operatorListingType.term_id);

      this.selectedSubCategories = []; //reset
      this.selectedPaginationItem = false; //reset
      this.selectedFacility = ""; //reset
      this.filterDataSelected = this.filterDataDefaults;
      //this.filterDataSelected.date = flatpickr.formatDate(new Date(), "Y-m-d") // todays date as default
      this.datePicker.setDate(this.filterDataDefaults.date);
      this.resetLocalStorage();
      this.updateUrl();
      this.getPosts();
    },
    updateKeywords($keywords) {
      if (!this.displaySearchBtn) {
        this.keywords = $keywords; // automatically update the list
        this.selectedPaginationItem = false; //reset

        // this.debounceKeywords(function() {
        //   console.log("Time elapsed!!!!! get new data");
        //   this.getPosts();
        // }, 900);
      } else {
        this.filterData["keywords"] = $keywords; // save it for when user presses submit
      }
    },
    updateFilter(item, value) {
      if (item !== null && value !== null) {
        this.filterDataSelected[item] = value;
      }
      this.selectedPaginationItem = false; //reset
      //this.updateUrl();
      this.getPosts();
      this.updateLocalStorage(item, value);
    },
    toggleMapView() {
      this.isMapView = !this.isMapView;
      //if(this.isMapView)
      // EventBus.$emit('update-operator-map'); //lili add
      //this.getOperators();
    },
    updateUrl() {
      let url = `${this.base_url}/${this.pageInfo.operator_listing_type.slug}/`;

      if (this.selectedCategory) {
        const term = this.getCategoryById(
          this.selectedCategory[this.selectedCategory.length - 1]
        );

        if (term) url += term.slug + "/";

        //TODO this only adds 1 subcategory t url, what if multiple are selected, maybe we can add additional GET vars
      }

      // we don't need the below code now that this data is in local storage
      /*
        url += `?date=${this.filterDataSelected.date}&adults=${this.filterDataSelected.adults}&children=${this.filterDataSelected.children}&infants=${this.filterDataSelected.infants}`

        if (this.pageInfo.operator_listing_type.slug =='accommodation')
            url += `&period=${this.filterDataSelected.period}`

        */

      console.log("REPLACING URL HERE");
      window.history.replaceState(
        { html: "lili", selectedCats: this.selectedSubCategories },
        "",
        url
      ); // todo, what data if any do we need to keep

      //todo add catgory to url
    },
    updateLocalStorage(item = null, value = null) {
      let filterData = JSON.parse(
        localStorage.getItem("wisdombeselectedfilter")
      );

      if (filterData == null) {
        localStorage.setItem(
          "wisdombeselectedfilter",
          JSON.stringify(this.filterDataSelected)
        );
      } else {
        //if localstorage is not null and no user selection has been made set selected to localstorage data
        filterData.type = this.operatorListingType.slug;
        if (item === null && value === null)
          this.filterDataSelected = filterData;
        //user selection has been made
        localStorage.setItem(
          "wisdombeselectedfilter",
          JSON.stringify(this.filterDataSelected)
        );
      }
    },
    resetLocalStorage() {
      localStorage.removeItem("wisdombeselectedfilter");
      localStorage.setItem(
        "wisdombeselectedfilter",
        JSON.stringify(this.filterDataDefaults)
      );
    },
    getCategoryById(termId) {
      console.warn("term = " + termId);
      const term = this.categories.filter(item => {
        return termId == item.term_id;
      });

      if (term) return term[0];
    },
    getPostsTours: function(isNewSearch = true) {
      console.warn("GETTINGPOSTSTOURS");
      var _this = this;

      this.noResults = false;

      this.queryString = ""; // if there is no category specified, if will get all post regardless of what category
      // if (this.postType === "posts" && this.pageInfo.news_cat_id)
      //   this.queryString = `&categories=${this.pageInfo.news_cat_id}`;

      //if (this.category != "") this.queryString = `&categories=${this.category}`;

      if (this.keywords != "") this.queryString += `&search=${this.keywords}`;

      if (this.dateRange && this.dateRange.length > 1) {
        let from = this.dateRange[0] + "T00:00:00.000Z";
        let to = this.dateRange[1] + "T23:59:59.000Z";

        this.queryString += `&after=${from}&before=${to}`;
      }

      if (this.selectedLocation !== "")
        this.queryString += `&operator_location=${this.selectedLocation}`;

      if (this.selectedFacility !== "") {
        let facilityId = this.facilities.filter(facility => {
          return facility.label == this.selectedFacility;
        })[0].term_id;
        this.queryString += `&operator_facilities=${facilityId}`;
      }

      //if(this.operatorListingType.slug != 'see-do')
      this.queryString += `&operator_type=${this.pageInfo.operator_listing_type.slug}`; // needed for tours & accomodation

      //if (this.operator_category.parent != 0 && this.pageInfo.operator_listing_type.term_id != this.operator_category.term_id ) this.queryString += `&operator_category=${this.selectedCategory}`

      // if (this.selectedSubCategories.length > 0)
      //   this.selectedSubCategories.forEach(item => {
      //     this.queryString += `&category[]=${item}`;
      //   });
      // else if (this.selectedCategory)
      //   // this.selectedCategory.forEach(item => {
      //     this.queryString += `&category[]=${this.selectedCategory}`;
      //   // });

      // this.queryString += `&category_taxonomy=${this.operatorListingType.taxonomy}`;

      if (isNewSearch) {
        this.pageNumber = 1; //reset
        this.isNewSearch = true;
      } else {
        this.pageNumber++; // ie we are 'loading more'
        this.isNewSearch = false;
      }

      this.getPostsRequest(
        `${this.pageInfo.site_url}/wp-json/wisdom-operators/v1/operators?exclude_nocoords=0&post_type=${_this.postType}${_this.queryString}`,
        {}
      ).then(function(response) {
        if (response) {
          // check incase request was previously cancelled is wont produce results

          _this
            .getPostsRequest(_this.operatorRequest,{})
            .then(function(beResponse) {
              let newPosts = response.data;

              if (isNewSearch) _this.operators = newPosts;
              // replace all old posts with new results
              else _this.operators.push(...newPosts); // we are 'loading more': append new results

              _this.pagesTotal = Math.ceil(
                response.data.length / _this.itemsPerPage
              );

              if (response.data.length < 1) _this.noResults = true;

              /********************* */

              if (beResponse) {
                // check incase request was previously cancelled is wont produce results

                let beOperatorsID = beResponse.data.map(item => {
                  return item.OperatorId
                })

                // only show operators matching webapi
                _this.operators = _this.operators.filter(operator => {
                  return beOperatorsID.includes(operator.operator_data.be_id)
                })

                beResponse.data.forEach(item => {
                  let operator_data = {};

                  operator_data.has_hot_deals = false;

                  item.Items.forEach(operatorProduct => {
                    if (operatorProduct.Availability.Specials.length > 0)
                      operatorProduct.Availability.Specials.forEach(special => {
                        if (special.IsHotDeal)
                          operator_data.has_hot_deals = true;
                      });
                  });

                  operator_data.items = item.Items;
                  operator_data.is_gold_medal = item.IsGoldMedal;

                  for (let i = 0; i < _this.operators.length; i++) {
                    if (
                      item.OperatorId === _this.operators[i].operator_data.be_id
                    ) {
                      let wp = _this.operators[i].operator_data;

                      _this.operators[i].operator_data = Object.assign(
                        {},
                        wp,
                        operator_data
                      );

                      break;
                    }
                  }
                });
              }
            });
        }
      });
    },
    getPosts: function(isNewSearch = true) {
      if (this.operatorListingType.slug !== "accommodation") {
        this.getPostsTours(isNewSearch);
        return;
      }

      console.warn("GETTINGPOSTS");

      var _this = this;

      this.queryString = ""; // if there is no category specified, if will get all post regardless of what category

      if (this.keywords != "") this.queryString += `&search=${this.keywords}`;

      if (this.dateRange && this.dateRange.length > 1) {
        let from = this.dateRange[0] + "T00:00:00.000Z";
        let to = this.dateRange[1] + "T23:59:59.000Z";

        this.queryString += `&after=${from}&before=${to}`;
      }

      if (this.selectedLocation !== "")
        this.queryString += `&operator_location=${this.selectedLocation}`;

      //if(this.operatorListingType.slug != 'see-do')
      ////         this.queryString += `&operator_type=${this.pageInfo.operator_listing_type.slug}`; // needed for tours & accomodation

      /*
        if (this.selectedSubCategories.length > 0 )
          this.selectedSubCategories.forEach(item => { this.queryString +=  `&operator_category[]=${item}`});
        else if (this.selectedCategory != "" )
          this.queryString += `&operator_category[]=${this.selectedCategory}`;
   */

      console.warn(`query string = ${this.queryString}`);

      if (isNewSearch) {
        this.pageNumber = 1; //reset
        this.isNewSearch = true;
      } else {
        this.pageNumber++; // ie we are 'loading more'
        this.isNewSearch = false;
      }

      this.getPostsRequest(this.operatorRequest, {}
      ).then(function(response) {
        if (response) {
          // check incase request was previously cancelled is wont produce results
          console.warn(response);

          let newPosts = [];

          response.data.forEach(item => {
            let beItem = {};

            beItem.title = { rendered: item.OperatorName };
            beItem.description = item.Description;
            beItem.url = false; // todo need to get permalink and then append # Bookeasy ID

            if (item.PrimaryImage)
              beItem.fimg = item.PrimaryImage.FullSizeImage;
            else if (item.OtherImages && item.OtherImages[0])
              beItem.fimg = item.OtherImages[0].FullSizeImage;
            // ThumbnailImage too small
            else beItem.fimg = false;

            let operator_data = {};

            operator_data.is_proud_member = true; //assume all bookeasy are members
            //operator_data.phone = false //don't need
            operator_data.address = item.Address;
            //operator_data.email = false //don't need
            //operator_data.website = false //don't need
            operator_data.latitude = item.Latitude;
            operator_data.longitude = item.Longitude;
            //operator_data.price_from = false;
            operator_data.has_hot_deals = false;
            operator_data.be_id = item.OperatorId;

            //let prices = [];
            item.Items.forEach(operatorProduct => {
              // if(operatorProduct.Availability.IsAvailable)
              //     prices.push(operatorProduct.Availability.Cost);

              if (operatorProduct.Availability.Specials.length > 0)
                operatorProduct.Availability.Specials.forEach(special => {
                  if (special.IsHotDeal) operator_data.has_hot_deals = true;
                });
            });

            // prices.sort((a,b) => { return a-b }); // sort lowest price first

            // if(prices.length > 0)
            //     operator_data.price_from = prices[0]; // display the lowest price in the list view

            operator_data.items = item.Items;
            operator_data.is_gold_medal = item.IsGoldMedal;

            beItem.operator_data = operator_data;

            /* for filtering */
            beItem.locations = item.Locations;
            beItem.location = item.Location;
            beItem.categories = item.TypeGrouping;
            beItem.facilities = item.Facilities;

            // get url from our list
            //let urlData = _this.operatorUrls.some( element => { return element.OperatorId == item.be_id });

            for (let i = 0; i < _this.operatorUrls.length; i++) {
              if (item.OperatorId === _this.operatorUrls[i]["be_id"]) {
                beItem.id = _this.operatorUrls[i].id;
                beItem.url = _this.operatorUrls[i].url;
                if(_this.bookEasy.campaignId)
                    beItem.url += `?c=${_this.bookEasy.campaignId}`

                break;
              }
            }

            if (beItem.url) newPosts.push(beItem);
            else console.warn("missing from wp urls", beItem);

            //   if(!item.operator_data.is_proud_member){
            //       // non member don't have full pages ie a link
            //       item["fimg"] = false; // they don't have custom images (incase there is already one set)
            //       item["url"] = false;
            //   }
          });

          if (isNewSearch) _this.operators = newPosts;
          // replace all old posts with new results
          else _this.operators.push(...newPosts); // we are 'loading more': append new results

          _this.pagesTotal = Math.ceil(
            response.data.length / _this.itemsPerPage
          );
        }
      });
    },
    loadMorePosts() {
      this.getPosts(false);
    },
    displayMorePosts() {
      this.pageNumber++;
    },
    subCategoriesClass(id, arrayName = "selectedSubCategories") {
      let cssClass = [];

      cssClass.push("subcategory-btn");

      if (this[arrayName].includes(id)) cssClass.push("is-selected");

      return cssClass;
    },
    appClasses() {
      let cssClass = [];

      cssClass.push("article-list");

      if (this.isLoading) cssClass.push("is-loading");

      if (this.isLoading && this.isNewSearch) cssClass.push("is-new-search");

      return cssClass;
    },
    getFromPrice(operatorData) {
      if (!operatorData.be_id || !operatorData.items) return false;

      let prices = [];
      operatorData.items.forEach(operatorProduct => {
        if (this.operatorListingType.slug == "accommodation") {
          if (
            operatorProduct.Availability.IsAvailable &&
            ((operatorProduct.MaxNumberOfGuests >= this.selectedGuestsTotal &&
              !operatorProduct.Availability.IsConstrained) ||
              (operatorProduct.MaxNumberOfGuests >=
                this.selectedGuestsTotalUnconstrained &&
                !operatorProduct.Availability.IsConstrained))
          )
            prices.push(operatorProduct.Availability.Cost);
        } else {
          if (operatorProduct.Availability.IsAvailable)
            prices.push(operatorProduct.Availability.Cost);
        }
      });

      prices.sort((a, b) => {
        return a - b;
      }); // sort lowest price first

      if (prices.length > 0) return Math.ceil(prices[0]); // display the lowest price in the list view and round up to nearest dollar

      return false;
    },
    changeListingType(type) {
      console.warn(`changin type to ${type}`);
    }
  },
  computed: {
    mainCategories() {
      // first level of categories (ie the ones under see-do, tours, accomm). Don't ever change.
      return this.categories.filter(item => {
        if (this.pageInfo.operator_listing_type.term_id == item.parent) return true;
      });
    },
    subCategories() {
      // second level of categories, ie children of the mainCategories. Changes when selectedCategory (in MainCategories) changes
      return this.categories.filter(item => {
        if (this.selectedCategory == item.parent)
          // display all (if no category selected)
          return true;
      });
    },
    filteredData() {
      /* Displayed list of operator */
      return this.filteredDataAllByPagination.slice(
        0,
        this.pageNumber * this.itemsPerPage
      );
    },
    filteredDataAll() {
      /* Same as filteredData but returns ALL AND DOES NOT include pagination (used for generating the pagination links) */

      var words = this.keywords.split(" ").filter(word => word !== "");
      console.warn(`searching`, words);

      let sortedData = this.operators
        .filter(item => {
          // filter by Location
          if (this.operatorListingType.slug == "tours") return true; // tours are already filtered by location

          if (!this.selectedLocation) return true;

          return item.locations.some(location => {
            return location.LocationId == this.selectedLocation;
          });
        })
        .filter(item => {
          // filter by facility
          if (this.operatorListingType.slug == "tours") return true; // tours are already filtered by facilities

          if (!this.selectedFacility) return true;

          if (item.facilities)
            return item.facilities.some(facility => {
              return facility.FacilityName == this.selectedFacility;
            });

          return false;
        })
        .filter(item => {
          // filter by Category
          if (this.operatorListingType.slug == "tours") return true; // tours

          if (this.selectedCategory.length < 1) return true;

          return item.categories.some(type => {
            return this.selectedCategoryNames.includes(type);
          });
        })
        .filter(item => {
          /* filter by keywords */

          // what text are we searching through?
          let searchString = `${item.title.rendered} ${item.operator_data.address} ${item.description}`;

          return words.every(word => {
            return searchString.toLowerCase().match(word.toLowerCase());
          });
        })
        .filter(item => {
          /* filter by Guests */

          if (
            this.operatorListingType.slug == "accommodation" &&
            this.showAll == false
          ) {
            return item.operator_data.items.some(operatorProduct => {
              return (
                (operatorProduct.MaxNumberOfGuests >=
                  this.selectedGuestsTotal &&
                  !operatorProduct.Availability.IsConstrained) ||
                (operatorProduct.MaxNumberOfGuests >=
                  this.selectedGuestsTotalUnconstrained &&
                  !operatorProduct.Availability.IsConstrained)
              );
            });
          } else {
            return true;
          }
        })
        .filter(item => {
          /* filter by Instantly Bookable only */

          if (this.displayInstantlyBookableOnly && !item.operator_data.be_id)
            return false;

          return this.displayInstantlyBookableOnly
            ? item.operator_data.is_gold_medal &&
                this.getFromPrice(item.operator_data)
            : true;
        })
        .sort((a, b) => {
          if (this.selectedSortOrder == "name") {
            if (a.title.rendered == b.title.rendered) return 0;
            else return a.title.rendered < b.title.rendered ? -1 : 1;
          } else if (this.selectedSortOrder == "price") {
            if (!this.getFromPrice(a.operator_data))
              // if no price at all, move to bottom
              return 1;
            else if (!this.getFromPrice(b.operator_data))
              // if no price at all, move to bottom
              return -1;
            else
              return (
                this.getFromPrice(a.operator_data) -
                this.getFromPrice(b.operator_data)
              );
          } else if (this.selectedSortOrder == "confirmation") {
            if (!this.getFromPrice(a.operator_data))
              // if no price at all, move to bottom
              return 1;
            else if (!this.getFromPrice(b.operator_data))
              // if no price at all, move to bottom
              return -1;
            else if (
              a.operator_data.is_gold_medal &&
              b.operator_data.is_gold_medal
            )
              return (
                this.getFromPrice(a.operator_data) -
                this.getFromPrice(b.operator_data)
              );
            else if (a.operator_data.is_gold_medal) return -1;
            else if (b.operator_data.is_gold_medal) return 1;
            // no instant bookables, so just compare price
            else
              return (
                this.getFromPrice(a.operator_data) -
                this.getFromPrice(b.operator_data)
              );
          } else if (this.selectedSortOrder == "deals") {
            if (a.operator_data.has_hot_deals == b.operator_data.has_hot_deals)
              return 0;
            else
              return a.operator_data.has_hot_deals <
                b.operator_data.has_hot_deals
                ? 1
                : -1;
          } else if (this.selectedSortOrder == "location") {
            if (a.location == b.location) return 0;
            else return a.location < b.location ? -1 : 1;
          }
        });

      if (this.isSortOrderReversed) sortedData.reverse();

      return sortedData;
    },
    filteredDataAllByPagination() {
      /* Same as filteredDataAll but also filters by pagination */

      return this.filteredDataAll.filter(item => {
        /* filter by pagination character */

        if (!this.selectedPaginationItem) return true;

        if (this.selectedPaginationItem == item.paginationKey) return true;
      });
    },
    filteredDataAllWithMarkers() {
      return this.filteredDataAll.filter(item => {
        if (item.operator_data.latitude) return true;
      });
    },
    selectedCategoryNames() {
      if (this.operatorListingType.slug == "tours") return false; // not needed for tours (only accom)

      let $cats = [];

      // this.selectedCategory.forEach(id => {
        let term = this.getCategoryById(this.selectedCategory);
        console.warn("lili", term);
        $cats.push(this.decodeEntities(term.label));
      // });

      return $cats;
    },
    selectedGuestsTotal() {
      return (
        parseFloat(this.filterDataSelected.adults) +
        parseFloat(this.filterDataSelected.children) +
        parseFloat(this.filterDataSelected.infants)
      );
    },
    selectedGuestsTotalUnconstrained() {
      return (
        parseFloat(this.filterDataSelected.adults) +
        parseFloat(this.filterDataSelected.children)
      );
    },
    operatorRequest(){
        let request = `https://webapi.bookeasy.com.au/be/get${this.bookEasy.listingType}RatesGrid?q=${this.bookEasy.vcId}&date=${this.filterDataSelected.date}&period=${this.filterDataSelected.period}&adults=${this.filterDataSelected.adults}&children=${this.filterDataSelected.children}&infants=${this.filterDataSelected.infants}&enforceBookingConditions=${this.bookEasy.enforceBookingConditions}&key=${this.bookEasy.apiKey}&enforceEntirePeriod=${this.bookEasy.enforceEntirePeriod}`;

        if(this.bookEasy.campaignId)
            request += `&CampaignId=${this.bookEasy.campaignId}`;

        return request;
    }
  }
};
</script>

<style lang="scss">
.article-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem 0;
}

.article-item {
    // max-width: 24rem;
    // margin: 2rem 1rem;
    text-align: center;
    position: relative;

    .image-container {
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        background-color: $brand-primary;
        height: 14em;

        @include vendor-prefix('transition', 'transform ease-out 0.18s');
    }

    .content-container {
        padding: 1em 1em 1em 1em;
        text-align: left;
        color: $brand-body-text;

        @media print {
            background: none;
            margin: 1em 0 0;
            padding-bottom: 0;
        }
    }

    &--operator {
        @extend .operator-grid-operator;

        .heading {
            text-align: left;
            margin-top: 0;
        }

        .book {
            @extend .button;

            width: 100%;
        }

        .address {
            color: $brand-primary;
            font-weight: 400;
        }
    }
}

/* Pagination */

.pagination {
  margin: 2em 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-item {
  @extend .button;
  @extend .button--slim;

  margin-left: 0.2em;
  margin-right: 0.2em;
  background: transparent;
  color: $brand-body-text;

  &.active {
      @extend .button;
      @extend .button--secondary;
      @extend .button--slim;
  }
}

/** MAP */
.operator-feed {
  display: block;

  &__views {
    display: flex;
    justify-content: center;

    .operator-feed__view-button {
      padding-left: 3em !important;
    }
  }

  .text-center {
    text-align: center;
  }
}

.operator-feed__map-container {
  position: relative;
}

.operator-feed__map {
  height: 500px;
  position: relative;

  @media screen and (min-width: $tablet-wide) {
      height: 600px;
  }
}

.article-list {
  &__items {
      font-size: 90%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      max-width: $desktop-wide;
      margin: auto;
      padding: 1em;

      @media (max-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: $phablet) {
        grid-template-columns: repeat(1, 1fr);
      }
  }

  &__item {
      @extend .article-item;
      @extend .article-item--operator;

      min-width: 250px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;

      .article-list--cols-4 & {
          flex: 1 1 20%;
      }

      .content-container {
          flex: 2;
          justify-items: unset;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
      }
  }

  &.is-loading.is-new-search {
      .article-list__items,
      .operator-feed__map-container {
          position: relative;

          &::after {
              content: '';
              width: 100vw;
              height: 100%;
              position: absolute;
              display: block;
              background: url('../assets/images/loader.svg') center 2em rgba(255, 255, 255, 0.83);
              background-repeat: no-repeat;
              background-size: 3rem;
              z-index: 1;
              left: 0;
              top: 0;
          }
      }
  }
}

.load-more-wrapper {
  text-align: center;
  margin: 1em 0 2em 0;

  .loader {
      width: 100%;
      height: 3em;
      background: url('../assets/images/loader.svg') center center rgba(255, 255, 255, 0.83);
      background-size: 3rem;
      background-repeat: no-repeat;
  }
}

.map-info-window {
  @extend .article-item;
  @extend .article-item--operator;

  margin: 0;
}

/* Google map info window */

.gm-style-iw.gm-style-iw-c {
  padding: 0;
  border-radius: 0;

  .gm-style-iw-d {
      overflow: initial !important;
      max-width: initial !important;
  }

  & > button { // close button
      img {
          width: 20px !important;
          height: 20px !important;
          margin: 10px 0 !important;
      }
  }
}

.gm-style .gm-style-iw-t {
  &::after {
      top: -1px !important;
  }
}

.bookeasy-feed {

  label {
    color: #fff;
    margin-right: 0.75rem;
    margin-left: 1rem;
    letter-spacing: 1px;
  }

  input,
  select {
    cursor: pointer;
    padding: 0.75rem 0.5rem;
  }

  select {
    appearance: none;
    padding-right: 2.5rem;
    position: relative;
    background-image: url('../assets/arrow.svg');
    background-position: center right 1rem;
    background-repeat: no-repeat;
    background-color: #ffffff;
  }

  .input-container {
    display: flex;
    align-items: center;
    margin: 0.5rem;

    input,
    select {
      border-radius: 0;
    }

    &.submit {
      width: 100%;
      display: flex;
      justify-content: center;

      .btn {
        background-color: $brand-primary;
        border: none;
        padding: 1rem 4.5rem;
        color: #fff;
        letter-spacing: 1px;
        margin-top: 1rem;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background-color: #fff;
        }
      }
    }
  }

  .filter__advanced {
    & > div {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
    }
  }

  .input-container--checkbox {
    color: #fff;
  }

  .filter {
    &__advanced-sub-options {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__advanced-search-btn {
      margin: auto;
      display: block;
      background: transparent;
      border: none;
      color: #fff;
      outline: none;
    }

    form {
      flex-direction: row;
    }
  }

  .operator-grid-operator {
      background: #fff;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  }

  .button {
    display: inline-block;
    text-decoration: none;
    border-radius: 0;
    background: $brand-primary;
    color: #fff;
    font-family: "Poppins";
    font-weight: 900;
    font-size: 0.9375rem;
    letter-spacing: 0.14062em;
    text-align: center;
    text-transform: uppercase;
    padding: 0.6em 2em 0.5em 2em;
    cursor: pointer;
    outline: none;
    border: solid transparent 1px;
    transition: all 0.15s ease-in-out;

    &:hover {
        color: $brand-primary;
        background: #fff;
        border: solid $brand-primary 1px;
    }

    &--ghost {
        border: 2px solid $brand-primary;
        background: transparent;
        color: $brand-primary;

        &:hover {
            background: $brand-primary;
            color: #fff;
        }
    }

    &--secondary {
        background: $brand-secondary;

        &:hover {
            background: $brand-primary;
            border: solid $brand-primary 1px;
            color: #fff;
        }
    }

    &--slim {
        padding: 0.3em 1em 0.25em 1em;
    }
  }

  .wp-block-button {
      &__link {
          @extend .button;
      }

      &.secondary {
          .wp-block-button__link {
              @extend .button--secondary;
          }
      }

      &.outline {
          .wp-block-button__link {
              @extend .button--ghost;
          }
      }
  }

  .wp-block-file {
      a {
          @extend .button;
      }

      & &__button {
          background: inherit;
      }
  }

  .btn {
      @extend .button;
  }

  .input-container {
    label {
      color: #fff;
    }
  }

  .operator-feed__view-button {
    @extend .button;

    padding-left: 3em;
    margin: 1.0em;
    background: transparent;
    color: #fff;

    svg {
        height: 1em;
        max-width: 2em;
        position: relative;

        @media screen and (min-width: $tablet-wide) {
            position: absolute;
            margin-left: -2em;
        }

        path {
            fill: #fff;
        }
    }

    &--map {
        svg {
            margin-right: 0.25em;

            @media screen and (min-width: $tablet-wide) {
                // margin-left: -2.5em; // map icon is wider
                margin-right: initial;
            }
        }
    }

    &--list {
        //
    }

    &.active {
        background: #fff;
        color: $brand-primary;

        svg {
            path {
                fill: $brand-primary;
            }
        }
    }

    &:hover {
        border: solid #fff 1px;
        //margin-top: 0.4em;
        transition: all .15s ease-in-out;

        &:not(.active) {
            background: #fff;
            color: $brand-primary;

            svg {
                path {
                    fill: $brand-primary;
                }
            }
        }
    }
  }
}
</style>
