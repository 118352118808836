export default {
  methods: {
    decodeHTML(html) {
      var txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    }
  },
  computed: {
    alphaPagination() {
      let letters = []; // store first letters of operator titles

      this[this.paginationItems].forEach(item => {

        let title = this.decodeHTML(item.title.rendered); // need to decode, incase there are html entities eg for whats on

        let i = 0;
        let letter = '';

        do {
          letter = title.charAt(i).toLowerCase();

          if (!isNaN(parseInt(letter, 10)) ) { // if number
            letter = '#';
            break;
          } else if(letter.toLowerCase() != letter.toUpperCase()) { // if letter
            break;
          } 
          i++; //must not be a char or number, loop again';
        } while (i < item.title.rendered.length)

        let lettersIndex = letters.findIndex( element => { return element.label == letter });

        if (lettersIndex !== -1) {
          letters[lettersIndex].count++;
        }else {
          letters.push({'label': letter, 'count': 1});
        }

        item.paginationKey = letter;
      });

      return letters.sort( (a, b) => {
        if (a.label == b.label)
            return 0;
        else
            return a.label < b.label ? -1 : 1;
      });
    }
  }
}