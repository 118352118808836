import Vue from 'vue'
import BookTripSearch from './components/BookTripSearch.vue'
import BookEasyFeed from './components/BookEasyFeed.vue'

let pageInfo = window.pageInfo

pageInfo.images = {
  mapPinIcon: "https://wisdom-site-visitshellharbour-com-au.s3.ap-southeast-2.amazonaws.com/uploads/marker.svg",
  mapPinIconGreen: "https://wisdom-site-visitshellharbour-com-au.s3.ap-southeast-2.amazonaws.com/uploads/marker-green.svg",
  mapCluster: "https://wisdom-site-visitshellharbour-com-au.s3.ap-southeast-2.amazonaws.com/uploads/cluster.svg"
}

Vue.config.productionTip = false
Vue.prototype.pageInfo = pageInfo

if(document.getElementById('wisdom-book-trip-search')) {
  new Vue({
    render: h => h(BookTripSearch),
  }).$mount('#wisdom-book-trip-search')
}

if(document.getElementById('wisdom-bookeasy-feed')) {
  new Vue({
    render: h => h(BookEasyFeed),
  }).$mount('#wisdom-bookeasy-feed')
}
