<template>
  <div :class="['filter', ...appClasses()]">
    <div>
      <h2 v-if="heading" :class="['filter__heading']" v-html="heading"></h2>
    </div>
    <div class="inner-container">
      <form
        :action="formAction"
        name="searchfilter"
        :method="formMethod"
        @submit.prevent="$emit('submit', $event)"
        class="search-filter-form"
      >
        <div v-if="this.displayKeywords" class="input-container">
          <label for="filter-keywords">Keywords</label>
          <input
            id="filter-keywords"
            type="text"
            placeholder="keyword"
            @keyup="$emit('update:keywords', $event.target.value)"
            v-model="keywords"
          />
        </div>
        <slot name="precategories"></slot>
        <slot name="categories">
          <div v-if="categories.length > 0" class="input-container">
            <label for="filter-category">Category</label>
            <select
              id="filter-category"
              @change="$emit('update:category', $event.target.value)"
              v-model="category"
            >
              <option value>Category</option>
              <option
                v-for="(category, index) in categories"
                :value="category.value"
                :key="index"
                v-html="category.label"
              ></option>
            </select>
          </div>
        </slot>
        <slot name="postcategories"></slot>
        <div
          v-if="this.displayDateRange"
          class="input-container date-range-picker"
        >
          <label for="filter-date-picker">Date Range</label>
          <input
            id="filter-date-picker"
            class="flatpickr flatpickr-input active"
            type="text"
            placeholder="Select Date..."
          />
        </div>
        <div class="input-container reset" v-if="displayResetBtn">
          <button type="button" @click="resetForm()" class="btn">Reset</button>
        </div>
        <div class="input-container submit" v-if="displaySearchBtn">
          <button
            type="submit"
            @click="$emit('click:search', $event)"
            class="btn"
          >
            Search
          </button>
        </div>
      </form>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import flatpickr from "flatpickr";
import flatpickrStyles from "flatpickr/dist/flatpickr.min.css";
/* eslint-enable no-unused-vars */

export default {
  data() {
    return {
      category: "",
      keywords: "",
      dateRangePicker: ""
    };
  },

  props: {
    heading: {
      type: String,
      default: "",
      required: false
    },
    categories: Array,
    displaySearchBtn: Boolean,
    displayDateRange: Boolean,
    selectedCategory: [Number, String],
    displayResetBtn: Boolean,
    displayKeywords: {
      type: Boolean,
      default: true,
      required: false
    },
    formAction: {
      type: String,
      default: "",
      required: false
    },
    formMethod: {
      type: String,
      default: "",
      required: false
    }
  },

  watch: {
    selectedCategory: function(newVal) {
      // watch selectedCategory so we can get the initial prop value (if a prop needs to be calculated it may not be available until after this component is mounted)
      this.category = newVal;
    }
  },

  created() {
    this.category = this.selectedCategory; // this wont work if the selectedCategory prop needs to be calculated, because this will run before we even get the final prop value, which is why I added the watcher aswell
  },

  mounted() {
    if (this.displayDateRange) {
      let datePicker = document.getElementById("filter-date-picker");
      let _this = this;

      //flatpickr(".flatpickr", {
      this.dateRangePicker = flatpickr(datePicker, {
        mode: "range",
        altInput: true,
        altFormat: "d/m/Y", //format for display only
        //onChange: function(selectedDates, dateStr, instance) {
        onChange: function(selectedDates) {
          if (selectedDates.length > 1) {
            // emit event only when both dates selected

            let dateRanges = [];
            dateRanges.push(flatpickr.formatDate(selectedDates[0], "Y-m-d"));
            dateRanges.push(flatpickr.formatDate(selectedDates[1], "Y-m-d"));

            //_this.$emit('update:daterange', selectedDates);
            _this.$emit("update:daterange", dateRanges);
          } else if (selectedDates.length == 0) {
            // they've just deleted the date
            _this.$emit("update:daterange", []); // sent empty sarray
          }
        }
      });
    }
  },

  methods: {
    appClasses() {
      let cssClass = [];

      if (this.categories.length > 0) cssClass.push("filter--with-categories");

      if (this.displayDateRange) cssClass.push("filter--with-date");

      if (this.displaySearchBtn) cssClass.push("filter--with-submit");

      return cssClass;
    },
    resetForm() {
      this.category = this.keywords = this.dateRange = "";

      if (this.displayDateRange) this.dateRangePicker.clear(false); //dont triggerChangeEvent, the reset event below should habdle the event

      this.$emit("click:reset", "");
    }
  }
};
</script>

<style lang="scss">
.search-filter-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 2rem 1rem;

  @media all and (max-width: 480px) {
    justify-content: space-between;
  }

  label {
    color: #fff;
    margin-right: 0.75rem;
    margin-left: 1rem;
    letter-spacing: 1px;
  }

  input,
  select {
    cursor: pointer;
    padding: 0.75rem 0.5rem;
  }

  select {
    appearance: none;
    padding-right: 2.5rem;
    position: relative;
    background-image: url('../assets/arrow.svg');
    background-position: 85% 15px;
    background-repeat: no-repeat;
    background-color: #fff;
  }

  .input-container {
    display: flex;
    align-items: center;
    margin: 0.5rem;

    input,
    select {
      border-radius: 0;
      border: none;
    }

    &.submit {
      width: 100%;
      display: flex;
      justify-content: center;

      .btn {
        background-color: transparent;
        border: none;
        padding: 0 0 0.25rem 0;
        color: #fff;
        letter-spacing: 1px;
        margin-top: 1rem;
        cursor: pointer;
        border-bottom: solid 1px white;
      }
    }
  }
}
</style>
