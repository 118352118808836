<template>
  <svg width="36px" height="37px" viewBox="0 0 36 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <!-- Generator: sketchtool 56.3 (101010) - https://sketch.com -->
      <title>4E23FE08-78A1-46B9-910C-2FA8FD9A2B09</title>
      <desc>Created with sketchtool.</desc>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Mobile-Stylesheet" transform="translate(-307.000000, -2047.000000)" fill="#FFFFFF">
              <g id="Buttons/various" transform="translate(0.000000, 966.000000)">
                  <g id="Group-2" transform="translate(-0.000076, 0.798095)">
                      <g id="MAP-white" transform="translate(307.000076, 1080.201905)">
                          <g id="Group-3">
                              <path d="M33.7010847,34.6148266 C33.4953416,34.8836142 33.1831477,35.0379652 32.844399,35.0379652 L21.6167361,35.0379652 L30.6858281,23.1125627 L33.887893,33.6887207 C33.9721761,34.0121887 33.9045187,34.3490384 33.7010847,34.6148266 L33.7010847,34.6148266 Z M2.39811066,34.6030605 C2.19236753,34.3268898 2.13163752,33.9791963 2.23092994,33.6494988 L5.44246241,23.001125 L9.43771225,24.1351089 L9.44025229,35.0379658 L3.26357109,35.0379658 C2.91881858,35.0379658 2.60339197,34.8794618 2.39811066,34.6030605 L2.39811066,34.6030605 Z M24.2456754,28.3381169 L19.1505889,35.0379652 L11.4037022,35.0379652 L11.401393,24.6925264 L24.2456754,28.3381169 Z M7.8065453,17.8191768 L28.2831816,17.8191768 C28.7685598,17.8191768 29.1964409,18.1458749 29.3236737,18.6135423 L29.983391,20.7931446 L25.5214669,26.6605593 L6.00912165,21.1226113 L6.77413508,18.5856253 C6.91222081,18.1271867 7.32717075,17.8191768 7.8065453,17.8191768 L7.8065453,17.8191768 Z M35.7795284,33.1624511 L31.2143863,18.0840422 C30.8502372,16.7721741 29.6469516,15.8571426 28.2831818,15.8571426 L7.80654552,15.8571426 C6.45455227,15.8571426 5.28405632,16.726261 4.89381403,18.0196716 L0.350839608,33.0835452 C0.0702808033,34.0135734 0.242079775,34.9938984 0.821670392,35.7732671 C1.40149192,36.5528666 2.29142907,37 3.2635711,37 L32.8443984,37 C33.7999147,37 34.6808462,36.564864 35.2611296,35.80626 C35.8416439,35.0474252 36.0305304,34.0839427 35.7795284,33.1624511 L35.7795284,33.1624511 Z" id="Fill-1"></path>
                              <g id="Geo" transform="translate(8.711622, 0.000000)">
                                  <path d="M9.09831409,4.21026581 C6.35154767,4.19533879 4.10354605,6.43011774 4.09264726,9.18711308 C4.08165166,11.934107 6.32217715,14.1983544 9.06235746,14.209647 C11.8103699,14.220679 14.0450213,11.9900077 14.0573531,9.22336818 C14.0694077,6.4531567 11.8655509,4.22534302 9.09831409,4.21026581 Z M9.19535562,22.0660693 C6.88840012,19.7910622 4.5123765,17.5789695 2.29356316,15.2232422 C-0.803057784,11.93567 -0.733989665,6.51969313 2.31866059,3.19116729 C5.49418785,-0.271101035 10.2932175,-0.989670096 14.3039871,1.39711877 C19.1425703,4.27653895 20.1994731,11.1583413 16.3288475,15.219681 C14.0748978,17.5843113 11.6400458,19.7817636 9.24273956,22.0965372 C9.27205336,22.1147389 9.22707877,22.0971308 9.19535562,22.0660693 Z" id="Fill-4"></path>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
</template>

<script>
export default {

}
</script>