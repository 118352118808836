<template>
    <article class="article-list__item">
        <a v-bind:href="item.url" :class="['image-container', { 'image-container--is-empty' : !bkgImageStyle(item.fimg)} ]" :style="bkgImageStyle(item.fimg)" rel="external" :title="item.title.rendered"></a>
        <slot name="content">
        <div class="content-container">
            <h3 class="heading" v-if="item.url" ><a v-bind:href="item.url" rel="external" :title="item.title.rendered" v-html=item.title.rendered ></a></h3>
            <h3 class="heading" v-else v-html=item.title.rendered></h3>
            <p v-if="item.displayDate" class="date">{{item.displayDate}}</p>
            <slot name="details"></slot>
            <div v-if="item.description" v-html="$options.filters.truncate($options.filters.paragraphWrap(item.description), 80)"></div>
            <slot name="button">
            <a v-if="item.url" class="btn read-more" v-bind:href="item.url">Read More</a>
            </slot>
        </div>
        </slot>
        <slot name="footer"></slot>
    </article>
</template>

<script>


export default {
    props: {
        /*
        * Profile - only used for localstorage key
        */
        item: {
            type: Object,
            required: true,
        },
    },
    methods: {
        bkgImageStyle(fimg) {
            if (!fimg) return;
            return {
                'background-image': `url(${fimg})`,
            };
        },
    },
    filters: {
        truncate: function (text, length, suffix = '&hellip;') {
            text = text.replace('[&hellip;]',''); // remove any ellipses added by wp excerpt
            //let closingPIndex = text.lastIndexOf("</p>");
            let truncatedText = text.substring(0, length);
            if (truncatedText == text)
                return text
            else
                return truncatedText + suffix;
        },
        /* Wrap text in p tags if not already formatted */
        paragraphWrap (text) {
            // //let closingPIndex = text.lastIndexOf("</p>");
            if (text.charAt(0) != "<")
                return `<p>${text}</p>`;
            else
                return text
        },
    },
};

</script>
