<template>
  <div class="operator-feed__map-container">
    <div id="of_map" class="operator-feed__map"></div>
  </div>
</template>

<script>
import MarkerClusterer from "@google/markerclusterer";
import mixins from "./utilities.js";

export default {
  name: "operator-map",
  mixins: [mixins],
  props: [
    "operatorData"
    //     'bus'
  ],
  watch: {
    operatorData: function(newVal, oldVal) {
      // watch operatorData and update clusters on change
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      this.updateMapMarkerClusters();
    }
  },
  data: function() {
    return {
      operators: [
        {
          TradingName: "",
          Latitude: "",
          Longitude: ""
        }
      ],
      mapName: "map",
      map: null,
      infoWindow: null,
      infoWindowContentString: [],
      bounds: null,
      markerActive: false,
      mapStyles: [
        {
          featureType: "landscape",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ececec"
            },
            {
              visibility: "on"
            }
          ]
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#eaeaea"
            }
          ]
        },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#bcefb1"
            }
          ]
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c3eeb5"
            },
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "poi.sports_complex",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#f5f5f5"
            }
          ]
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#fff0c2"
            }
          ]
        },
        {
          featureType: "transit.line",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#b6b6b6"
            }
          ]
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#59d5e8"
            }
          ]
        }
      ],
      markers: [],
      markerCluster: null,
      activeInfoWindowOperatorId: ""
    };
  },
  created: function() {
    //
  },
  mounted() {
    console.log("MAP COMP MOUNTED");

    this.initMap();
  },
  methods: {
    // returns array of markers
    createMarkers(operatorArray) {
      let markers = [];

      let _this = this;

      this.infoWindow = new window.google.maps.InfoWindow({
        content: "",
        maxWidth: 300
      });

      console.group("creating markers");

      for (var i = 0; i < operatorArray.length; i++) {
        const item = operatorArray[i];

        const latitude = Number(item.operator_data.latitude);
        const longitude = Number(item.operator_data.longitude);

        if (!(latitude && longitude) || longitude < 0) continue; // added the < 0 condition, to not to include operators where the client has entered the latitude and lonitude incorrectly (eg longitude instead of latitude)

        console.warn(
          "coords:" + latitude + " " + longitude + ". " + item.title.rendered
        );

        const position = new window.google.maps.LatLng(latitude, longitude);

        let markerOptions = {
          position: position,
          map: this.map,
          title: item.title.rendered,
          icon: this.pageInfo.images.mapPinIcon,
          animation: window.google.maps.Animation.DROP //BOUNCE
        };

        const marker = new window.google.maps.Marker(markerOptions);

        let infoWindowContentString = this.formatInfoWindowString(item);

        _this.infoWindowContentString.push(infoWindowContentString);

        marker.addListener("click", function() {
          _this.markerActive = marker;
          console.log(marker);
          console.log(item);

          let markerIndex = _this.markers.indexOf(this);
          _this.infoWindow.setContent(
            _this.infoWindowContentString[markerIndex]
          );
          _this.infoWindow.open(_this.map, marker);
          _this.activeInfoWindowOperatorId = item.id;
        });

        markers.push(marker);
        this.map.fitBounds(this.bounds.extend(position));
      }

      console.groupEnd();

      // CREATE CLUSTERS
      var styles = [
        {
          url: this.pageInfo.images.mapCluster,
          height: 40,
          width: 40,
          textColor: "#ffffff",
          textSize: 20
        }
      ];

      // DEBUG: set the resulting object to a var and output
      this.markerCluster = new MarkerClusterer(this.map, markers, {
        styles: styles
      });

      return markers;
    },
    updateMapMarkerClusters() {
      console.warn("deleting all markers");

      if (this.markerCluster) this.markerCluster.clearMarkers();

      this.infoWindowContentString = []; // reset infowindow content

      // for (var i = 0; i < this.markers.length; i++) {

      //   console.warn(this.markers[i]);
      //    console.dir(this.markers[i]);
      //    this.markers[i].setMap(null);
      // }
      // this.markers = [];

      // clear marker array
      while (this.markers.length) {
        this.markers.pop().setMap(null);
      } // setmap here doesn't do anything (because we are using clusters), could be removed

      //generate new markers and add to cluster
      this.markers = this.createMarkers(this.operatorData);
    },
    initMap: function() {
      this.bounds = new window.google.maps.LatLngBounds(); //unhide thuis lili
      const element = document.getElementById("of_map");
      //const mapCentre = this.markerCoordinates[0]
      const options = {
        //center: new google.maps.LatLng(mapCentre.latitude, mapCentre.longitude), //hide this
        //center: new google.maps.LatLng(-34.397, 150.644), //hide this
        zoom: 8,
        mapTypeControl: false,
        scrollwheel: false,
        streetViewControl: false,
        styles: this.mapStyles
      };

      this.map = new window.google.maps.Map(element, options);

      this.markers = this.createMarkers(this.operatorData);

      //markerCluster.setMap(null);
    },
    formatInfoWindowString(item) {
      let imageStyle = "";
      let imageClass = "image-container";

      if (item.fimg) imageStyle = `background-image: url('${item.fimg}')`;
      else imageClass += " image-container--is-empty";

      return `
            <div class="map-info-window ${
              item.operator_data.is_proud_member ? "proud-member" : ""
            } ">
                ${
                  item.url
                    ? `<a href="${item.url}" class="${imageClass}" style="${imageStyle}" title="${item.title.rendered}"></a>`
                    : `<div class="${imageClass}"></div>`
                }

                <div class="content-container">
                    <h3 class="heading">
                        ${
                          item.url
                            ? `<a href="${item.url}" title="${item.title.rendered}">${item.title.rendered}</a>`
                            : `${item.title.rendered}`
                        }
                    </h3>
                    ${
                      item.displayDate
                        ? `<p class="date">${item.displayDate}</p>`
                        : `${
                            item.operator_data.address
                              ? `<p class="address">${item.operator_data.address}</p>`
                              : ""
                          }
                       ${
                         item.operator_data.phone  && false != false
                           ? `<p class="phone">${item.operator_data.phone}</p>`
                           : ""
                       }`
                    }
                    ${
                      item.operator_data.tagline
                        ? `<p class="tagline">${item.operator_data.tagline}</p>`
                        : ""
                    }
                    ${
                      item.description
                        ? `<p>${this.paragraphWrap(
                            this.truncate(item.description, 80)
                          )}</p>`
                        : ""
                    }
                    ${
                      item.operator_data.price_from && false != false
                        ? `<a href="${item.url}" class="btn book">Book Now <span class="book__price"><small>from </small>$${item.operator_data.price_from}</span></a>`
                        : ""
                    }
                    ${
                      item.operator_data.email & false != false
                        ? `<a href="mailto:${item.operator_data.email}" class="btn email">Email</a>`
                        : ""
                    }
                    ${
                      item.operator_data.website & false != false
                        ? `<a href="${item.operator_data.website}" class="btn web" target="_blank">Web</a>`
                        : ""
                    }
                    ${
                      item.operator_data.phone & false != false
                        ? `<a href="tel:${item.operator_data.phone}" class="btn call">Call</a>`
                        : ""
                    }
                    <div id="favourite-btn-app" data-id=${item.id}></div>
                </div>
            </div>
          `;
    },
    truncate: function(text, length, suffix = "&hellip;") {
      text = text.replace("[&hellip;]", ""); // remove any ellipses added by wp excerpt
      //let closingPIndex = text.lastIndexOf("</p>");
      let truncatedText = text.substring(0, length);
      if (truncatedText == text) return text;
      else return truncatedText + suffix;
    },
    /* Wrap text in p tags if not already formatted */
    paragraphWrap(text) {
      // //let closingPIndex = text.lastIndexOf("</p>");
      if (text.charAt(0) != "<") return `<p>${text}</p>`;
      else return text;
    }
    /* cool function to get the pixel location of the marker on the map - not used. Was looking at using it for the map overlay however i switch to using google's infowindow instead, Google have already done the hard work for us, because i would've had to listen for every map change so i could move the overlay with it. */
    /*
      getMarkerPixelLocation(marker){
        var scale = Math.pow(2, this.map.getZoom());
        var nw = new window.google.maps.LatLng(
            this.map.getBounds().getNorthEast().lat(),
            this.map.getBounds().getSouthWest().lng()
        );
        var worldCoordinateNW = this.map.getProjection().fromLatLngToPoint(nw);
        var worldCoordinate = this.map.getProjection().fromLatLngToPoint(marker.getPosition());
        var pixelOffset = new window.google.maps.Point(
            Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
            Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
        );

        return pixelOffset;
      },
      */
  }
};
</script>
