<template>
  <div :class="[...appClasses()]">
    <h2 class="bts-title">Book your trip to shellharbour</h2>
    <div class="filter-tabs">
      <div
        :class="['filter-tab', { 'active' : filterDataSelected['type'] == 'accommodation'} ]"
        @click="updateFilter('type', 'accommodation')"
      >Accommodation</div>
      <div
        :class="['filter-tab', { 'active' : filterDataSelected['type'] == 'tours'} ]"
        @click="updateFilter('type', 'tours')"
      >Tours / Activities</div>
    </div>
    <search-filter
      :displayKeywords="false"
      :categories="[]"
      :displaySearchBtn="true"
      :displayResetBtn="false"
      :displayDateRange="false"
      :formAction="formAction"
      @submit="submitForm"
      formmethod="post"
    >
      <template slot="precategories">
        <div class="input-container date-range-picker">
          <label for="book-trip-date-picker">Date</label>
          <input
            id="book-trip-date-picker"
            name="date"
            class="flatpickr flatpickr-input active"
            type="text"
            placeholder="Select Date..."
          />
        </div>
        <div
          v-if="this.filterDataSelected.type == 'accommodation'"
          class="input-container input-container--small"
        >
          <label for="filter-category">Nights</label>
          <select
            id="filter-category"
            name="period"
            @change="updateFilter('period', $event.target.value)"
            v-model="filterDataSelected.period"
          >
            <option v-for="(item, index) in 31" :value="index" :key="index">{{index}}</option>
          </select>
        </div>

        <div class="input-container input-container--small">
          <label for="filter-category">Adults</label>
          <select
            id="filter-category"
            name="adults"
            @change="updateFilter('adults', $event.target.value)"
            v-model="filterDataSelected.adults"
          >
            <option v-for="(item, index) in 46" :value="index" :key="index">{{index}}</option>
          </select>
        </div>
        <div class="input-container input-container--small">
          <label for="filter-category">Children</label>
          <select
            id="filter-category"
            name="children"
            @change="updateFilter('children', $event.target.value)"
            v-model="filterDataSelected.children"
          >
            <option v-for="(item, index) in 46" :value="index" :key="index">{{index}}</option>
          </select>
        </div>
        <div class="input-container input-container--small">
          <label for="filter-category">Infants</label>
          <select
            id="filter-category"
            name="infants"
            @change="updateFilter('infants', $event.target.value)"
            v-model="filterDataSelected.infants"
          >
            <option v-for="(item, index) in 46" :value="index" :key="index">{{index}}</option>
          </select>
        </div>
      </template>
    </search-filter>
  </div>
</template>

<script>
import SearchFilter from "./SearchFilter.vue";
//import mixins from './utilities.js'

/* eslint-disable no-unused-vars */
import flatpickr from "flatpickr";
import flatpickrStyles from "flatpickr/dist/flatpickr.min.css";
/* eslint-enable no-unused-vars */

export default {
  props: {},
  // mixins: [mixins, pagination],
  components: {
    SearchFilter
  },
  data: function() {
    return {
      datePicker: "",
      filterDataDefaults: {
        adults: 2,
        children: 0,
        infants: 0,
        period: 1, // only used for accom
        type: "accommodation"
      },
      filterDataSelected: {
        adults: 2,
        children: 0,
        infants: 0,
        period: 1, // only used for accom
        type: "accommodation",
        date: flatpickr.formatDate(new Date(), "Y-m-d")
      }
    };
  },
  mounted: function() {
    let _this = this;
    let datePicker = document.getElementById("book-trip-date-picker");

    console.log(datePicker)

    this.datePicker = flatpickr(datePicker, {
      defaultDate: "today",
      altInput: true,
      altFormat: "d/m/Y", //format for display only
      onChange: function(selectedDates) {
        if (selectedDates.length > 0) {
          if (
            flatpickr.formatDate(selectedDates[0], "Y-m-d") !=
            _this.filterDataSelected.date
          ) {
            // _this.filterDataSelected.date= flatpickr.formatDate(selectedDates[0], "Y-m-d");
            _this.updateFilter(
              "date",
              flatpickr.formatDate(selectedDates[0], "Y-m-d")
            );
          }
        }
      },
      "disable": [
        function(date) {
            // return true to disable
            let today = new Date()
            today.setHours(0,0,0,0);
            return date < today
        }
      ],
    });

    this.updateLocalStorage();

    this.filterDataDefaults.date = flatpickr.formatDate(new Date(), "Y-m-d"); // todays date as default
    this.datePicker.setDate(this.filterDataDefaults.date);

    if (this.filterDataSelected.date >= this.filterDataDefaults.date)
      this.datePicker.setDate(this.filterDataSelected.date);
    else this.filterDataSelected.date = this.filterDataDefaults.date;
  },
  methods: {
    appClasses() {
      let cssClass = [];

      cssClass.push("book-trip-search");

      // if(this.isLoading)
      //     cssClass.push("is-loading");

      return cssClass;
    },
    updateFilter(item = null, value = null) {
      if (item !== null && value !== null) {
        this.filterDataSelected[item] = value;
      }
      this.updateLocalStorage(item, value);
    },
    updateLocalStorage(item = null, value = null) {
      let filterData = JSON.parse(
        localStorage.getItem("wisdombeselectedfilter")
      );

      if (filterData == null) {
        localStorage.setItem(
          "wisdombeselectedfilter",
          JSON.stringify(this.filterDataSelected)
        );
      } else {
        //if localstorage is not null and no user selection has been made set selected to localstorage data
        if (item === null && value === null)
          this.filterDataSelected = filterData;
        //user selection has been made
        localStorage.setItem(
          "wisdombeselectedfilter",
          JSON.stringify(this.filterDataSelected)
        );
      }
    },
    submitForm() {
      document.searchfilter.submit();
    }
  },
  computed: {
    formAction() {
      return `/book/${this.filterDataSelected.type}/`;
    }
  }
};
</script>

<style lang="scss">
.book-trip-search,
.bookeasy-feed {
  background-image: url('../assets/images/pink-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;

  .filter-tabs {
    display: flex;
    justify-content: center;
  }

  .filter-tab {
    background-color: $purple;
    color: $purple;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: inherit;
    cursor: pointer;
    color: #fff;

    &.active {
      background-color: #fff;
      color: $purple;
    }
  }

  .bts-title {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    margin-bottom: 3rem;
    letter-spacing: 2px;
    font-family: "Eveleth Thin", sans-serif;
  }
}
</style>
