import axios from 'axios'

export default {
  methods: {
    makeAJAXRequest: function() {
      let _this = this;

      var call;

      return function(url) {
        if (call) {
          call.cancel(); // only one 'get posts' request at a time ie cancel previous one
        }
        console.warn(`fetching: ${url}`);

        call = axios.CancelToken.source();
        _this.isLoading = true;

        return axios
          .get(url, { cancelToken: call.token })
          .then(response => {
            console.warn("fetched ajax data");
            _this.isLoading = false;
            return response;
          })
          .catch(function(thrown, error) {
            if (axios.isCancel(thrown)) {
              console.log("previous ajax request canceled");
            } else {
              // handle error
              _this.isLoading = false;
              console.warn(error);
            }
          });
      };
    },
    delay() {
      // use this to delay the execution of code
      var timer = 0;

      return function(callback, ms) {
          let context = this,
          args = arguments;

          clearTimeout(timer);
          timer = setTimeout(function() {
          callback.apply(context, args);
          }, ms || 0);
      };
    },
    getQueryVariable(variable){
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
        }
        return(false);
    },
    decodeEntities(encodedString) {
        let translate_re = /&(nbsp|amp|quot|lt|gt);/g;
        let translate = {
            "nbsp":" ",
            "amp" : "&",
            "quot": "\"",
            "lt"  : "<",
            "gt"  : ">"
        };
        return encodedString.replace(translate_re, function(match, entity) {
            return translate[entity];
        }).replace(/&#(\d+);/gi, function(match, numStr) {
            var num = parseInt(numStr, 10);
            return String.fromCharCode(num);
        });
    },
    formatDisplayDate(dates){

        let dateString = '';

        if(Array.isArray(dates) && dates.length > 1) { // ie date range, 2 dates separated by :

            const date1Parts = new Date(dates[0])
                .toDateString()
                .split(" ")
                .slice(1)

            const date2Parts = new Date(dates[1])
                .toDateString()
                .split(" ")
                .slice(1)

            dateString = `${parseInt(date1Parts[1])} ${date1Parts[0]}${date1Parts[2] != date2Parts[2] ? ', '+ date1Parts[2] : ''} - ${parseInt(date2Parts[1])} ${date2Parts[0]}, ${date2Parts[2]}`;

        }else {
            let date = Array.isArray(dates) ? dates[0] : dates;

            const dateParts = new Date(date)
                .toDateString()
                .split(" ")
                .slice(1)

            // add a comma after day,
            dateString = parseInt(dateParts[1]) + " " + dateParts[0] + ", " + dateParts[2];
        }

        return dateString;
    }
  }
}
